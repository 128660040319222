export default function HomePage() {
	return (
		<a
			style={{
				display: "block",
				marginLeft: "auto",
				marginRight: "auto",
				width: "50%",
			}}
			href="https://www.pinterest.com/pin/244812929722867509/"
		>
			<img src={"/assets/sleep.gif"} />
		</a>
	);
}
