import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import { FaApple, FaAppStore, FaAndroid, FaWindows } from "react-icons/fa";
import { MdWebAsset } from "react-icons/md";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			light: "#757ce8",
			main: "#3f50b5",
			dark: "#002884",
			contrastText: "#fff",
		},
		secondary: {
			light: "#ff7961",
			main: "#f44336",
			dark: "#ba000d",
			contrastText: "#000",
		},
	},
});

function Copyright(props: any) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			<Link color="inherit" href="/">
				myClarity
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

const tiers = [
	{
		title: "Web",
		icon: <MdWebAsset fontSize="32px" style={{ marginRight: "12px" }} />,
		// description: [
		// 	"10 users included",
		// 	"2 GB of storage",
		// 	"Help center access",
		// 	"Email support",
		// ],
		buttonText: "Launch Webapp",
		buttonVariant: "contained",
		link: "https://staging.myclarity.io/",
	},
	{
		title: "macOS",
		icon: <FaApple fontSize="32px" style={{ marginRight: "12px" }} />,
		// description: [
		// 	"20 users included",
		// 	"10 GB of storage",
		// 	"Help center access",
		// 	"Priority email support",
		// ],
		buttonText: "Download",
		buttonVariant: "contained",
		link: "https://drive.google.com/drive/folders/19mHCHi7kTUct4ayjfOdjsTAV2SBSC_Pc?usp=sharing",
	},
	{
		title: "Windows",
		icon: <FaWindows fontSize="32px" style={{ marginRight: "12px" }} />,
		// description: [
		// 	"50 users included",
		// 	"30 GB of storage",
		// 	"Help center access",
		// 	"Phone & email support",
		// ],
		buttonText: "Download",
		buttonVariant: "contained",
		link: "https://drive.google.com/drive/folders/19mHCHi7kTUct4ayjfOdjsTAV2SBSC_Pc?usp=sharing",
	},
	{
		title: "iOS",
		icon: <FaAppStore fontSize="32px" style={{ marginRight: "12px" }} />,
		// description: [
		// 	"50 users included",
		// 	"30 GB of storage",
		// 	"Help center access",
		// 	"Phone & email support",
		// ],
		buttonText: "Join TestFlight",
		buttonVariant: "contained",
		link: "https://testflight.apple.com/join/KPHgyHBY",
	},
	{
		title: "Android",
		icon: <FaAndroid fontSize="32px" style={{ marginRight: "12px" }} />,
		// description: [
		// 	"50 users included",
		// 	"30 GB of storage",
		// 	"Help center access",
		// 	"Phone & email support",
		// ],
		buttonText: "Download",
		buttonVariant: "contained",
		link: "https://drive.google.com/drive/folders/19mHCHi7kTUct4ayjfOdjsTAV2SBSC_Pc?usp=sharing",
	},
];

export default function AlphaPage() {
	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<GlobalStyles
					styles={{
						ul: { margin: 0, padding: 0, listStyle: "none" },
					}}
				/>
				<CssBaseline />
				{/* Hero unit */}
				<Container
					disableGutters
					maxWidth="sm"
					component="main"
					sx={{ pt: 8, pb: 6 }}
				>
					<Typography
						component="h1"
						variant="h2"
						align="center"
						color="text.primary"
						gutterBottom
					>
						You have been invited to join the <b>myClarity</b>{" "}
						Alpha! 🎉
					</Typography>
					<Typography
						variant="h5"
						align="center"
						color="text.secondary"
						component="p"
						padding="8px"
					>
						Designed to run across all your devices. Select the
						platforms that work best for you or try them all.
					</Typography>
					<Box textAlign="center">
						<Button
							style={{ marginTop: "24px" }}
							variant={"outlined"}
							href={
								"https://docs.google.com/forms/d/e/1FAIpQLSfOZBMouHdLlPma-_3V7pA5Ns_QuYqOo1FLPiRBLJ7cndd9Jg/viewform?usp=sf_link"
							}
						>
							{"Feedback Form"}
						</Button>
					</Box>
				</Container>
				{/* End hero unit */}
				<Container maxWidth="md" component="main">
					<Grid container spacing={5} alignItems="flex-end">
						{tiers.map((tier) => (
							// Enterprise card is full width at sm breakpoint
							<Grid
								item
								key={tier.title}
								xs={12}
								sm={tier.title === "Enterprise" ? 12 : 6}
								md={4}
							>
								<Card>
									<CardHeader
										title={
											<Typography
												variant="h5"
												// align="center"
												style={{
													display: "inline-flex",
													verticalAlign: "middle",
													alignItems: "center",
												}}
											>
												{tier.icon} {tier.title}
											</Typography>
										}
										//subheader={tier.subheader}
										titleTypographyProps={{
											align: "center",
										}}
										action={
											tier.title === "Pro" ? (
												<StarIcon />
											) : null
										}
										subheaderTypographyProps={{
											align: "center",
										}}
										sx={{
											backgroundColor: (theme) =>
												theme.palette.mode === "light"
													? theme.palette.grey[200]
													: theme.palette.grey[700],
										}}
									/>
									{/* <CardContent>
									<ul>
										{tier.description.map((line) => (
											<Typography
												component="li"
												variant="subtitle1"
												align="center"
												key={line}
											>
												{line}
											</Typography>
										))}
									</ul>
								</CardContent> */}
									<CardActions>
										<Button
											fullWidth
											variant={
												tier.buttonVariant as
													| "outlined"
													| "contained"
											}
											href={tier.link}
										>
											{tier.buttonText}
										</Button>
									</CardActions>
								</Card>
							</Grid>
						))}
					</Grid>
				</Container>
				{/* Footer */}
				<Container
					maxWidth="md"
					component="footer"
					sx={{
						borderTop: (theme) =>
							`1px solid ${theme.palette.divider}`,
						mt: 8,
						py: [3, 6],
					}}
				>
					<Copyright sx={{ mt: 5 }} />
				</Container>
				{/* End footer */}
			</ThemeProvider>
		</React.Fragment>
	);
}
